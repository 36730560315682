import React, { useState, useEffect, useRef, useReducer } from "react";
import { useStaticQuery, graphql } from 'gatsby';
import axios from "axios";
import { useSpring, animated as a } from "react-spring";
import {getCovidData} from "../Data";
import Button from '../Button';
import Results from "../Results";
import './Travel.css';

import Autocomplete from "./Autocomplete"

const infectionTimelineData = data => {
    const chartNodes = data.sort((a, b) =>
            new Date(a.last_update).getTime() - new Date(b.last_update).getTime()
        ).map(d => d.cases - d.recovered);
    return chartNodes;
}

const CountrySearch = ({searchExpanded, setSearchExpanded, ...props}) => {
    const allCountries = useStaticQuery(graphql`
        query allCountries {
            allInternalCountries(filter: {id: {ne: "dummy"}}) {
                edges {
                    node {
                        name
                        slug
                        status
                        quarantine
                        allowed_to_travel
                        restricted
                        summary
                        detail
                        alpha2
                        alpha3
                    }
                }
            }
        }`)

    let travelAdvisory = allCountries.allInternalCountries.edges
    travelAdvisory = travelAdvisory.map(p => p.node);

    const {countriesData, flags} = props;
    const [items, setItems] = useState(countriesData);
    const [isLoading, setIsLoading] = useState(false);
    const [pageHeight, setPageHeight] = useState();
    const [isError, setIsError] = useState(false);
    const [isRandom, setIsRandom] = useState(false);
    const [state, setState] = useState({
        activeItem: 0,
        filteredItems: [],
        displayItems: false,
        inputValue: ''
    })
    const fieldRef = useRef();
    const heightRef = useRef(0);
    const itemsRef = useRef(0)
    const [itemsArray, setItemsArray] = useState([]);

    const [selected, dispatchSelected] = useReducer((state, action) => {
        switch (action.type) {
            case "add":
                return [{ id: state.length, name: action.name, data: action.data, flag: action.flag }, ...state];
            case "update":
                const updated = state.filter((_, index) => [{ data: action.data }])
                return updated;
            case "remove":
                const removedItem = state.filter((_, index) => index === action.index)
                const remove = state.filter(s => s.name !== removedItem[0].name);
                let newCountryItemsList = new Set([...items, removedItem[0]])
                newCountryItemsList = Array.from(newCountryItemsList).sort((a, b) => a.name > b.name ? 1 : -1)
                setItems(newCountryItemsList)
                return remove;
            case "empty":
                return []
            default:
                return state;
        }
    }, []);

    useEffect(
        () => {
            heightRef.current = window.innerHeight;
            window.addEventListener("resize", function () {
                setPageHeight(window.innerHeight);
            });
        }, []
    )

    useEffect(
        () => {
            setItemsArray(selected.map(item => {
                const selectedCountry = countriesData.filter(function(country){
                    const c = country.name === item.name;
                    return c;
                });
                const countryArray = selectedCountry.map(c => {
                    // const f = flags.filter(f => {
                    //     let flag = f.name === c.alpha2
                    //     return flag;
                    // })
                    // c.flag = f[0][`publicURL`]
                    c.data = item.data
                    return c
                })
                return selectedCountry;
            }))
        }, [selected]
    )

    useEffect(
        () => {
            itemsArray && itemsArray.length > 0 ? setSearchExpanded(false) : setSearchExpanded(true)
        }, [itemsArray]
    )

    useEffect(
        () => {
            const container = document.querySelector('#countryResults');
            container.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth'
            });
        }, [isLoading]
    )

    useEffect(
        () => {
            if (isRandom) {
                randomizeCountry()
            }
        }, [isRandom]
    )

    function handleAddItems(e) {
        const newCountry = countriesData.filter(item => item.name.toLowerCase().includes(e.name.toLowerCase()))
        setItems(items.filter(i => i.name.toLowerCase() !== e.name.toLowerCase()));
        setIsLoading(true);
        countryCovidData(newCountry);
    }

    const countryCovidData = country => {
        const advisory = travelAdvisory.filter(
            c => country[0].name.toLowerCase().includes(c.name.toLowerCase()))[0]

        getCovidData(country[0]).then(responses => {
            const countryNames = items.map(a => a.name.toLowerCase());
            let allowed_in = [], restricted_in = [], allowed_count = "";

            if(advisory.status) {
                let allowed = advisory.allowed_to_travel.map(a => items.filter(i => i.name.toLowerCase() === a.toLowerCase()))
                allowed_in = allowed.filter(a => a.length)
                allowed_count = allowed_in.length;
                allowed_in = allowed_in.sort(() => .5 - Math.random()).slice(0, 5)
                let restricted = advisory.restricted.map(a => a.toLowerCase())
                // allowed_in = Array.from(allowed.reduce(
                //     (r, p) => countryNames.reduce((q, s) => s.includes(p) ? q.add(s) : q, r),
                //     new Set()
                // )).sort();

                // restricted_in = Array.from(restricted.reduce(
                //     (r, p) => countryNames.reduce((q, s) => s.includes(p) ? q.add(s) : q, r),
                //     new Set()
                // )).sort();
            }

            const all = {
                current: responses.current,
                diff: responses.diff,
                timeline: responses.timeline,
                prediction: responses.prediction,
                advisory,
                allowed_in,
                allowed_count
            }

            dispatchSelected({
                type: "add",
                name: country[0].name,
                flag: country[0].flag,
                data: all
            });
            setIsLoading(false);
            setIsRandom(false)
        })
        .catch((error) => {
            setIsLoading(false);
            if (isRandom) {
                randomizeCountry()
            } else {
                setIsError(true);
                setTimeout(
                    function(){
                        setIsError(false);
                        !itemsArray.length && setSearchExpanded(true);
                    }, 2000);
            }
        });
    }

    const randomizeCountry = () => {
        const country = items[Math.floor(Math.random() * items.length)];
        handleAddItems(country);
    }

    const formStyle = useSpring({
        height: searchExpanded ? `100%` : "35%",
        maxHeight: searchExpanded ? `${heightRef.current}px` : "55px",
        config: { mass: 1, tension: 600, friction: 50 },
    });

    const formLabelStyle = useSpring({
        height: searchExpanded ? "50px" : "0px",
        marginTop: searchExpanded ? "-85px" : "0px",
        transform: `scale(${searchExpanded ? 1 : 0})`,
        config: { mass: 1, tension: 400, friction: 20 },
    });

    return (
        <>
        <a.div
            style={formStyle}
            className="uk-container uk-container-small travel-form-container">
            <div
                className="travel-search-form uk-width-2-3@s uk-width-3-4@m uk-width-2-3@l">
                <a.div style={formLabelStyle}
                    className="search-form-label uk-text-center">
                    <h1 style={{color: "#fff"}} className="uk-margin-remove-vertical">Search by country</h1>
                    <small className="uk-light">Where do you want to go?</small>
                </a.div>
                <a.div>
                    <Autocomplete
                        items={items}
                        state={state}
                        setState={setState}
                        isError={isError}
                        isLoading={isLoading}
                        fieldRef={fieldRef}
                        itemsArray={itemsArray}
                        handleAddItems={handleAddItems}
                        setSearchExpanded={setSearchExpanded}
                        />
                    {itemsArray.length === 0 && searchExpanded ?
                        <div className="uk-text-center uk-margin">
                            <small
                                onClick={(e) => setIsRandom(true)}
                                style={{color: "#fff", cursor: "pointer"}}
                                >
                                Show me anywhere
                            </small>
                        </div>
                    : null}
                </a.div>
            </div>
        </a.div>

        <Results items={itemsArray} dispatchSelected={dispatchSelected} handleAddItems={handleAddItems} />
        </>
    )
}

export default CountrySearch;
