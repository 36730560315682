import axios from "axios";

const infectionTimelineData = data => {
    const chartNodes = data.sort((a, b) =>
            new Date(a.last_update).getTime() - new Date(b.last_update).getTime()
        ).map(d => d.cases - d.recovered);
    return chartNodes;
}

const processCovidData = async result => {
    const current = result[1].data[0]
    const diff = result[0].data;
    const timeline = infectionTimelineData(result[1].data);
    const prediction = result[2].status === 200 ? result[2].data.map(p => p.cases) : null;
    const data = {current, diff, timeline, prediction}
    return data
}

export const getCovidData = async country => {
    const diffByCountry = axios.get(`https://covid19-api.org/api/diff/${country.alpha2}`).then(r => r).catch(e => {return []})
    const timelineByCountry = axios.get(`https://covid19-api.org/api/timeline/${country.alpha2}`).then(r => r).catch(e => {return []})
    const predictionData = axios.get(`https://covid19-api.org/api/prediction/${country.alpha2}`).then(r => r).catch(e => {return []})
    const result = await axios.all([diffByCountry, timelineByCountry, predictionData])
    const res = await processCovidData(result)
    return res;
}
