import React, { useEffect, useState } from "react";

function Button(props) {
    const [classes, setClasses] = useState('');
    const { size, type, border, color } = props;
    useEffect(
        () => {
            let cls = '';
            if (size) {
                cls = `${cls} uk-button-${size}`;
            }
            if (type) {
                cls = `${cls} uk-button-${type}`;
            }
            if (border) {
                cls = `${cls} uk-border-${border}`;
            }
            if (color) {
                cls = `${cls} uk-button-text-color-${color}`;
            }
            setClasses(`${cls}`);
        }, [size, border, type, color]);

    return(
        <button className={`uk-button ${classes}`} {...props}></button>
    )
}

export default Button;
