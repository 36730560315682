import React, { useState, useEffect, useRef, useReducer } from "react";
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { useSpring, useTransition, animated as a } from "react-spring";
import { Sparklines, SparklinesLine } from 'react-sparklines';

const urlify = text => {
    const urlRegex = /(\b(https):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    return text.replace(urlRegex, function(url) {
        const urlParts = url.replace('http://','').replace('https://','').split(/[/?#]/);
        const domain = urlParts[0];
        return '<a className="uk-link-text uk-text-bold" target="_blank" href="' + url + '">'+domain+'</a>';
    })
}

const slugify = text =>
  text
    .toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')

const calculateCurrent = (value, cases) => {

    const percent = (value/cases * 100).toFixed(2)

    if(percent === 0 || percent === 'NaN') {
        return <small style={{fontSize: "13px"}} className="uk-text-uppercase uk-text-nowrap">No Data</small>
    }
    return percent + '%'
}

const ResultCard = ({
        index,
        dispatchSelected,
        handleAddItems,
        country
    }) => {

    const {current, diff, prediction, allowed_count, allowed_in: allowed} = country.data;

    const images = useStaticQuery(graphql`
        query imagesQuery {
            deaths: file(relativePath: { eq: "icons/Deaths.png" }) {
                name
                childImageSharp {
                    fluid(maxWidth: 24) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            infected: file(relativePath: { eq: "icons/Infected.png" }) {
                name
                childImageSharp {
                    fluid(maxWidth: 24) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            recovered: file(relativePath: { eq: "icons/Recovered.png" }) {
                name
                childImageSharp {
                    fluid(maxWidth: 24) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            quarantine: file(relativePath: { eq: "icons/Quarantine.png" }) {
                name
                childImageSharp {
                    fluid(maxWidth: 24) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            openTravel: file(relativePath: { eq: "icons/open_travel.png" }) {
                name
                childImageSharp {
                    fluid(maxWidth: 24) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            partialTravel: file(relativePath: { eq: "icons/partial_travel.png" }) {
                name
                childImageSharp {
                    fluid(maxWidth: 24) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            noTravel: file(relativePath: { eq: "icons/no_travel.png" }) {
                name
                childImageSharp {
                    fluid(maxWidth: 24) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);

    // const cardStyle = useTransition({
    //     from: {
    //         transform: `scale(1)`
    //         },
    //     to: async next => {
    //         await next({
    //             transform: `scale(0.99)`
    //         })
    //         await next({
    //             transform: `scale(1)`
    //         })
    //     },
    //     config: { mass: 1, tension: 400, friction: 10 },
    //   })
    return (
        <div
            id={slugify(country.name)}
            className={`uk-card uk-card-small uk-card-default uk-box-shadow-small uk-box-shadow-hover-large
                ${country.data.advisory.status !== "closed" ? (country.data.advisory.status === "open" ? "open-destination" : "limited-destination") : "bad-destination" }`}
                data-uk-card
            >

            <div className="uk-card-header uk-position-relative uk-padding-small">
                <button
                    className="uk-drop-close uk-position-top-right uk-position-small uk-position-z-index"
                    type="button"
                    data-uk-close="ratio: 1"
                    onClick={() => dispatchSelected({ type: "remove", index })} />
                <div className="uk-grid uk-grid-collapse uk-width-1-1 uk-position-relative uk-flex-middle" data-uk-grid>
                    <div className="uk-width-auto">
                        <img
                            src={`${country.flag}`}
                            alt={`Flag of ${country.name}`}
                            width="28"
                            data-uk-img
                        />
                    </div>
                    <div className="uk-width-expand">
                        <h4 className="uk-margin-small-left uk-margin-remove-vertical uk-padding-remove uk-text-truncate">{country.name}</h4>
                    </div>
                </div>
            </div>


            <div className="uk-card-body uk-position-relative">

                <div className="uk-grid uk-grid-collapse uk-text-center" data-uk-grid>
                    <div className="uk-width-1-3 uk-flex uk-flex-column uk-flex-middle">
                        <Img
                        fluid={images.infected.childImageSharp.fluid}
                        loading="eager"
                        style={{width: "24px"}}
                        alt={`The number of people infected with Covid-19 today.`}
                        />
                        <h5 className="uk-width-1-1 uk-display-block uk-margin-small-top">
                            {current && current.cases.toLocaleString(`en-US`)}{diff && diff.new_cases > 0 && <span style={{color: "#d7504e"}} data-uk-icon="icon: arrow-up; ratio: 0.8"/>}
                            <div className="uk-width-1-1 uk-display-block uk-margin-remove-vertical">
                            {
                                diff && diff.new_cases !== 0 ?
                                <small
                                    style={{marginLeft: "4px", fontSize: "0.5rem", fontWeight: "100"}}
                                    className="uk-label uk-label-danger uk-text-small uk-border-rounded">
                                        <span>{`+${diff && diff.new_cases.toLocaleString(`en-US`)} (${diff && diff.new_cases_percentage}%)`}</span>
                                </small>
                                : <small
                                    style={{marginLeft: "4px", fontSize: "0.5rem", fontWeight: "100"}}
                                    className="uk-text-small uk-text-uppercase uk-text-muted uk-border-pill">
                                        <span>No change</span>
                                </small>
                            }
                            </div>
                        </h5>
                    </div>
                    <div className="uk-width-1-3 uk-flex uk-flex-column uk-flex-middle">
                        <Img
                        fluid={images.recovered.childImageSharp.fluid}
                        loading="eager"
                        style={{width: "24px"}}
                        alt={`The number of people reported recovered today.`}
                        />

                        <h5 className="uk-width-1-1 uk-display-block uk-margin-small-top">
                            {current && current.recovered.toLocaleString(`en-US`)}{diff && diff.new_recovered > 0 && <span style={{color: "#8bc34a"}} data-uk-icon="icon: arrow-up; ratio: 0.8"/>}
                            <div className="uk-width-1-1 uk-display-block uk-margin-remove-vertical">
                            {
                                diff && diff.new_recovered !== 0 ?
                                <small
                                    style={{marginLeft: "4px", fontSize: "0.5rem", fontWeight: "100"}}
                                    className="uk-label uk-label-success uk-text-small uk-border-pill">
                                        <span>{`+${diff && diff.new_recovered.toLocaleString(`en-US`)} (${diff && diff.new_recovered_percentage}%)`}</span>
                                </small>
                                : <small
                                    style={{marginLeft: "4px", fontSize: "0.5rem", fontWeight: "100"}}
                                    className="uk-text-small uk-text-uppercase uk-text-muted uk-border-pill">
                                        <span>No change</span>
                                </small>
                            }
                            </div>
                        </h5>
                    </div>
                    <div className="uk-width-1-3 uk-flex uk-flex-column uk-flex-middle">
                        <Img
                        fluid={images.deaths.childImageSharp.fluid}
                        loading="eager"
                        style={{width: "24px"}}
                        alt={`The number of people who have died from Cobid-19 today.`}
                        />
                        <h5 className="uk-width-1-1 uk-display-block uk-margin-small-top">
                            {current && current.deaths.toLocaleString(`en-US`)}{diff && diff.new_deaths > 0 && <span style={{color: "#d7504e"}} data-uk-icon="icon: arrow-up; ratio: 0.8"/>}
                            <div className="uk-width-1-1 uk-display-block uk-margin-remove-vertical">
                            {
                                diff && diff.new_deaths !== 0 ?
                                <small
                                    style={{marginLeft: "4px", fontSize: "0.5rem", fontWeight: "100"}}
                                    className="uk-label uk-label-danger uk-text-small uk-border-pill">
                                        <span>{`+${diff && diff.new_deaths.toLocaleString(`en-US`)} (${diff && diff.new_deaths_percentage}%)`}</span>
                                </small>
                                : <small
                                    style={{marginLeft: "4px", fontSize: "0.5rem", fontWeight: "100"}}
                                    className="uk-text-small uk-text-uppercase uk-text-muted uk-border-pill">
                                        <span>No change</span>
                                </small>
                            }
                            </div>
                        </h5>
                    </div>
                </div>

                <div className="uk-grid uk-child-width-1-2 uk-text-uppercase uk-text-small uk-text-center uk-margin-small-top" data-uk-grid>
                    <div>
                        {current ?
                        <Sparklines data={country.data.timeline}>
                            <SparklinesLine color={diff && diff.new_cases > 0 ? "#d7504e" : "#8bc34a"} />
                        </Sparklines>
                        : <small className="uk-h6 uk-display-block uk-text-uppercase">No Data</small>
                        }
                        <small style={{fontSize: "9px", fontWeight: "900"}}>Cases <span className="uk-text-muted">(Active)</span></small>
                    </div>
                    <div>
                        {prediction !== null ?
                            <Sparklines data={prediction.slice(0,5)}>
                                <SparklinesLine color={diff && diff.new_cases > 0 ? "#d7504e" : "#8bc34a"} />
                            </Sparklines>
                            : <small className="uk-h6 uk-display-block uk-text-uppercase">No Data</small>
                        }
                        <small style={{fontSize: "9px", fontWeight: "900"}}>Predicted <span className="uk-text-muted">(Next 5-days)</span></small>
                    </div>
                </div>

                <table className="uk-table uk-table-expand uk-table-middle uk-table-justify uk-table-small">
                    <tbody>
                        <tr>
                            <td className="uk-width-expand uk-text-small">
                                <Img
                                fluid={images.recovered.childImageSharp.fluid}
                                loading="eager"
                                alt={`The number of people reported recovered today.`}
                                style={{width: "12px", marginRight: "6px", display: "inline-block"}}
                                />
                                Recovery rate
                            </td>

                            <td className="uk-width-auto">
                                <p className="uk-h5 uk-text-right">
                                    {calculateCurrent(current && current.recovered, current && current.cases)}
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td className="uk-width-expand uk-text-small">
                                <Img
                                fluid={images.deaths.childImageSharp.fluid}
                                loading="eager"
                                alt="People who have died from Covid-19."
                                style={{width: "12px", marginRight: "6px", display: "inline-block"}}
                                />
                                Mortality rate</td>
                            <td className="uk-width-auto">
                                <p className="uk-h5 uk-text-right">
                                    {calculateCurrent(current && current.deaths, current && current.cases)}
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className="uk-text-center">
                    <div className="uk-grid uk-grid-small uk-flex uk-flex-row uk-child-width-auto uk-flex-center">
                        <div>
                            <Img
                            fluid={country.data.advisory.status !== "closed" ?
                                (country.data.advisory.status === "open" ?
                                    images.openTravel.childImageSharp.fluid
                                    : images.partialTravel.childImageSharp.fluid)
                                    : images.noTravel.childImageSharp.fluid}
                            loading="eager"
                            alt="People who have died from Covid-19."
                            style={{width: "32px"}}
                            />
                        </div>
                        {country.data.advisory.quarantine === "true" &&
                        <div>
                            <Img
                            fluid={images.quarantine.childImageSharp.fluid}
                            loading="eager"
                            alt={`Quarantine requirements of ${country.name}`}
                            style={{width: "32px"}}
                            />
                        </div>
                        }
                    </div>
                    <h6 className={`${country.data.advisory.status !== "closed" ? (country.data.advisory.status === "open" ? "uk-text-success" : "uk-text-primary") : "uk-text-danger"} uk-text-center uk-heading-line uk-margin-remove-top uk-margin-small-bottom`}>
                        <span>{country.data.advisory.status !== "closed" ? (country.data.advisory.status === "open" ? "Travel Allowed" : "Travel Limited") : "No Entry"} <small style={{color: "#aaa", fontSize: "10px", textTransform: "uppercase"}} className="uk-display-block uk-margin-remove-vertical">{country.data.advisory.quarantine === "true" && "Quarantine may be required"}</small></span>
                    </h6>
                </div>

                <div>
                    {country.data.advisory && country.data.advisory.summary ?
                        <p className="uk-h4" dangerouslySetInnerHTML={{__html: country.data.advisory.summary}} />
                        :
                        <p>Oops, I couldn't find any travel advisory info for {country.name}.</p>
                    }
                </div>
                {allowed.length > 0 &&
                    <div>
                        <p>Travelers from <span className="uk-text-bold">{country.name}</span> may be allowed entry into {allowed_count} countries
                        including {allowed.map((optionName, i) =>
                                <span
                                    key = {optionName[0].name}
                                    onClick = {() => {
                                        // handleAddItems(optionName[0])
                                        console.log(optionName[0])
                                    }}
                                    >
                                <span
                                    className="uk-text-capitalize">
                                    {optionName[0].name}</span>, </span>
                        )}
                        and more.
                        </p>
                    </div>
                }
                {country.data.advisory && country.data.advisory.detail &&
                    <div>
                        <ul className="uk-list" data-uk-accordion>
                            <li>
                                <a className="uk-accordion-title uk-button uk-button-large uk-button-text cta-button" href="#">Details</a>
                                <div className="uk-accordion-content uk-overflow-auto">
                                {country.data.advisory.detail.map((p, i) =>
                                    <p key={`detail-${i}`} className={`uk-text-small`} dangerouslySetInnerHTML={{__html: urlify(p)}} />
                                )}
                                </div>
                            </li>
                        </ul>
                    </div>
                }
            </div>
        </div>
    )
}

const Results = ({
        items,
        dispatchSelected,
        handleAddItems
    }) => {

        const countries = [].concat(...items)
        const transitions = useTransition(countries, item => item.id, {
            initial: { transform: 'translate3d(0%, 100%,0)', opacity: 0 },
            from: {  transform: 'translate3d(0%, 100%,0)', opacity: 0 },
            enter: { transform: 'translate3d(0%, 0%,0)', opacity: 1 },
            leave: { transform: 'translate3d(0%,100%,0)', opacity: 0 },
            config: { mass: 1, tension: 400, friction: 60 },
        })

        return (
            <section id="countryResults">
                <div className="country-results-container uk-container uk-margin-large">
                    <div className="uk-child-width-1-2@s uk-child-width-1-3@m" data-uk-grid="masonry: true">
                    {transitions.map(({ item, props, id }, index) =>
                        <a.div
                            key={item.id}
                            style={props}
                            className="result-card"
                            >
                            <ResultCard index={index} country={item} dispatchSelected={dispatchSelected} handleAddItems={handleAddItems} />
                        </a.div>
                    )}
                    </div>
                </div>
            </section>
        )
}
export default Results;
