import React, { useState, useEffect, useRef, useReducer } from "react";
import { useStaticQuery, graphql } from 'gatsby';
import axios from "axios";
import {countriesList} from "./Countries";
import { DataError } from "../Notifications/Error.js";

const Autocomplete = ({
        items,
        state,
        setState,
        isLoading,
        isError,
        fieldRef,
        itemsArray,
        handleAddItems,
        setSearchExpanded,
        ...props
    }) => {

    const handleChange = (e) => {
        const inputValue = e.currentTarget.value;
        const filteredItems = items.filter(
            x => x.name.toLowerCase().includes(inputValue.toLowerCase())
        );

        setState({
            activeItem: 0,
            filteredItems,
            displayItems: true,
            inputValue: e.currentTarget.value
        });
    };

    const handleClick = (e) => {
        setState({
            activeItem: 0,
            filteredItems: [],
            displayItems: false,
            inputValue: e.currentTarget.innerText
        });
        fieldRef.current.value = "";
        e.currentTarget.blur();
        const c = items.filter(i => i.name.includes(e.currentTarget.innerText.trim()));
        handleAddItems(...c)
    };

    const handleKeyDown = (e) => {
        const { activeItem, filteredItems } = state;
        if (e.keyCode === 13) {
            const inList = filteredItems && items.includes(filteredItems[activeItem]);
            if(!inList || !fieldRef.current.value) return;
            setState({
                activeItem: 0,
                filteredItems: [],
                displayItems: false,
                inputValue: filteredItems[activeItem]
            });
            fieldRef.current.value = "";
            e.currentTarget.blur();
            handleAddItems(filteredItems[activeItem]);
        }
        else if (e.keyCode === 38) {
            e.preventDefault();
            if (activeItem === 0) {
                return;
            }
            setState({
                activeItem: activeItem - 1,
                filteredItems,
                displayItems: true,
                inputValue: e.currentTarget.value
            });
        }
        else if (e.keyCode === 40) {
            e.preventDefault();
            if ((filteredItems && activeItem === filteredItems.length - 1) || activeItem >= 4) {
                return;
            }
            setState({
                activeItem: activeItem + 1,
                filteredItems,
                displayItems: true,
                inputValue: e.currentTarget.value
            });
        }
    };

    return (
            <div className="uk-inline uk-width-1-1">
                {isError && <DataError />}
                <span
                    className={`uk-form-icon ${isLoading ? "icon-loading" : ""}`} data-uk-icon="icon: search" />
                <input
                    id="searchCountryForm"
                    ref={fieldRef}
                    name="languages"
                    label="Items"
                    placeholder={isLoading ? "Just a moment, getting data..." : "Name country > Tap/Click/Key"}
                    className={`uk-input uk-form-large uk-width-expand uk-border-pill uk-box-shadow-medium ${isLoading ? "travel-form-isloading" : ""}`}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    onFocus={() => setSearchExpanded(false)}
                    onBlur={() => {
                        if(itemsArray.length === 0 && state.filteredItems && !state.inputValue.length) {
                                setSearchExpanded(true);
                            }
                        }}
                    value={(fieldRef.current && fieldRef.current.value) || ""}
                    autoComplete="off"
                    autoCapitalize="off"
                    autoCorrect="off"
                    />

                {state.displayItems && state.inputValue.length && state.filteredItems ?
                    <div className="uk-width-1-1 list-panel uk-panel uk-padding-remove uk-box-shadow-medium">
                        <ul className="uk-list">
                        {
                            state.filteredItems.map((item, index) => {
                                return (
                                    <li
                                        className={`${state.activeItem === index ? "active-item" : "default-item"}`}
                                        key = {item.name}
                                        onClick = {handleClick}>
                                        <div className="uk-width-1-1 uk-text-truncate">
                                            {item.name}
                                        </div>
                                    </li>
                                )
                            }).slice(0, 5)
                        }
                        </ul>
                    </div>
                    : null
                }
        </div>
    )
}

export default Autocomplete;
